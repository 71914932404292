<template>
  <div class="page">
    <aside class="first-half">
      <div>
        <figure class="logo">
          <img src="@/assets/img/logo.svg" alt="Logo"/>
        </figure>
        <form class="login-form">
          <div v-if="!showSuccess">
            <h1 class="title">Reset your password</h1>
            <p class="paragraph">Enter the email associated with your account and we will send you a link to
              reset your password.</p>
            <template v-if="showError">
              <p class="error-msg">
                {{ errorMsg }}
              </p>
            </template>

            <InputText label="Email" placeholder="Your email" type="email" class="mb-6" name="email"
                       v-model="email"/>

            <Button kind="primary" :loading="isLoadingData" type="submit" text="Reset password"
                    @click.native.prevent="resetPassword"
                    class="w-full"/>
            <router-link to="/login" class="login-link">Remembered your password? Login here</router-link>
          </div>
          <div class="success" v-else>
            <div class="icon">
              <i class="bx bxs-envelope"></i>
            </div>
            <h2>Check your inbox</h2>
            <p>We have sent you password recovery instructions to your email.</p>
            <Button kind="primary" text="Go back" class="w-full" @click.native="goToLogin()"/>
          </div>
        </form>
        <footer>
          <div class="footer-container">
                        <span class="credits">
                            Made with ♥ remotely from Ghana, Nigeria, Mauritius & Switzerland.
                        </span>
            <span class="credits copyrights">
                            © {{ new Date().getFullYear() }} Travel Afrique, LLC. All rights
                            reserved.
                        </span>
          </div>
        </footer>
      </div>
    </aside>
    <aside class="second-half">
      <img class="bg-img" :src="require(`@/assets/img/backgrounds/${imgPath}`)" alt="Background Image"/>
    </aside>
  </div>
</template>
<script>
import Button from "@/components/Buttons/Button";
import InputText from "@/components/Inputs/InputText";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PasswordReset",
  components: {
    Button,
    InputText,
  },
  data() {
    return {
      imgPath: "1.jpeg",
      isLoading: false,
      showError: false,
      showSuccess: false,
      errorMsg: "",
      email: "",
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      resetCredentials: "auth/resetCredentials",
    }),
    goToLogin() {
      this.$router.push("/login");
    },
    async resetPassword() {

      this.resetCredentials(this.email)
          .then(() => {
            this.$router.push(this.$route.query.redirect || {name: "Requests"});
          })
          .catch((e) => {
            this.showError = true;
            this.errorMsg = e.response.data.message;
            //hide the error after a minute
            setTimeout(() => {
              this.showError = false;
            }, 10000);
          });
    },

    getRandomImg(totalImg) {
      let random = Math.floor(Math.random() * totalImg);
      this.imgPath = random + ".jpeg";
    },
  },

  computed: {
    ...mapGetters({
      token: "auth/token",
      isLoadingData: "auth/isLoadingData"
    }),
  },
  beforeMount() {
    this.getRandomImg(45);
  },
};
</script>
<style scoped>
.page {
  @apply w-100vw h-100vh flex sm:flex-wrap bg-grey-lighter;
}

.first-half {
  @apply sm:h-full w-full md:w-5/8 px-4 py-8 sm:p-8;
  @apply flex justify-center items-center;
}

.second-half {
  @apply hidden md:block h-full w-3/8 p-8 ml-auto relative;
}

.bg-img {
  @apply h-full w-full object-cover absolute top-0 left-0;
}

.logo {
  @apply h-13 relative my-9;
}

.logo img {
  @apply absolute object-cover sm:left-0 top-0 h-full left-1/2 -translate-x-1/2 transform sm:transform-none sm:translate-x-0;
  @apply relative left-1/2 transform -translate-x-1/2;
}

.login-form {
  @apply relative left-1/2 transform -translate-x-1/2 bg-white;
  @apply w-full max-w-sm sm:max-w-lg px-8 py-12 sm:px-12 sm:py-14;
  @apply shadow-sm border border-grey-base rounded-md mb-4;
}

.title {
  @apply font-sans text-black-base font-semibold text-xl sm:text-2xl;
}

.paragraph {
  @apply text-black-lighter text-sm mt-2 mb-6;
}

.error-msg {
  @apply font-sans text-red-base text-sm mb-5;
}

footer {
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply max-w-sm sm:max-w-lg pb-12;
}

.footer-container {
  @apply flex justify-between flex-wrap;
}

.credits {
  @apply text-black-lighter text-xs w-full md:text-sm mb-2;
}

.copyrights {
  @apply text-left;
}

.login-link {
  @apply text-teal-dark underline text-sm text-center;
  @apply mt-6 block;
}

.icon {
  @apply bg-teal-lighter text-teal-dark;
  @apply rounded-full h-32 w-32;
  @apply flex items-center justify-center;
  @apply text-5xl;
  @apply relative left-1/2 transform -translate-x-1/2 mb-6;
}

.success h2 {
  @apply font-semibold text-xl text-black-base;
  @apply text-center mb-3;
}

.success p {
  @apply text-center text-black-light mb-8;
}
</style>